<template>
  <v-card class="my-5 pb-1">
    <v-toolbar color="green darken-2 mb-3" dark>
      <v-toolbar-title class="row justify-center d-flex align-center">
        <span class="">{{ driver.name }} : {{ driver.phone | normalizePhone }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="grey lighten-4 ma-1" v-for="v in list" :key="v.id">
      <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
        <v-avatar size="50px" class="grey lighten-2">
          <img alt="" v-if="v.vehicle_type.icon" :src="v.vehicle_type.icon" />
          <img alt="" v-else src="/12.jpg" />
        </v-avatar>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center d-flex align-center">
        <span v-if="v.vehicle_type.name" class="font-weight-bold">{{ v.vehicle_type.name }}</span>
        <span v-else class="font-italic">Без названия</span>
      </v-col>
      <v-col cols="5" class="col col-lg-6 col-md-6 col-sm-6">
        <div v-for="f in v.fields" :key="f.key" class="font-weight-bold">
          {{ f.label }} : {{ f.value }}
        </div>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn fab small @click="del(v.id)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row class="grey lighten-4 ma-1" v-for="v in inactive" :key="v.id">
      <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
        <v-avatar size="50px" class="grey lighten-2">
          <img alt="" v-if="v.icon" :src="v.icon" />
          <img alt="" v-else src="/12.jpg" />
        </v-avatar>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center d-flex align-center">
        <span v-if="v.name" class="font-weight-bold">{{ v.name }}</span>
        <span v-else class="font-italic">Без названия</span>
      </v-col>
      <v-col cols="5" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters d-flex align-center">
        <div v-for="f in v.fields" :key="f.key" class="font-weight-bold">
          {{ f.label }} : {{ f.value }}
        </div>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn small fab class="" @click="del(v.id)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";

export default {
  data: () => ({
    driver: {},
    list: [],
    inactive: []
  }),

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      let driverID = this.$route.params.id;
      axios.send({
        url: "/drivers/" + driverID,
        method: "GET",
      }).then((resp) => {
        this.driver = resp.data;
        this.fetchVehicles(driverID);
        this.fetchInactiveVehicles(driverID);
      });
      },
    fetchVehicles(id) {
      axios.send({
        url: "/drivers/vehicles/" + id,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      });
      },
    fetchInactiveVehicles(id) {
      axios.send({
        url: "/drivers/vehicles/inactive/" + id,
        method: "GET",
      }).then((resp) => {
        this.inactive = resp.data;
      });
    },
    del(id) {
      this.$confirm(
          "Вы точно хотите удалить транспорт",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/drivers/vehicles/" + id,
          method: "DELETE",
        }).then(() => {
          this.fetch();
        });
      });
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
  }
};
</script>
